/* SCROLL BAR */

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: hsla(229, 52%, 96%, 1); 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsla(40, 11%, 85%, 1);
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Cursor */
.cursor-pointer {
    cursor: pointer;
}