.ClerkTable {
    display: flex;
    flex-direction: column;
}

.ClerkTable table {
    border-collapse: separate;
    border-spacing: 0px 21px;
    table-layout: auto;
    width: 100%;
    padding: 0px 5px;
}


.ClerkTable thead tr {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.ClerkTable tbody tr {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000024;
    border-radius: 10px;
}

.ClerkTable td {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #7D7D7D;
    opacity: 1;
}

.ClerkTable th {
    font: normal normal 500 19px/29px Poppins;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
}

.ClerkTable td, .ClerkTable th {
    padding: 24px 16px;
    height: 88px;
    vertical-align: middle;
    text-align: center;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.ClerkTable th:first-child {
    padding-left: 32px;
}

.ClerkTable th:last-child {
    padding-right: 32px;
}

.ClerkTable td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
    padding-left: 32px;
}

.ClerkTable td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px;
    padding-right: 32px;
}

.ClerkTable td .Image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    opacity: 1;
}

.ClerkTable td .ImagePlaceHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 40px;
    width: 40px;
    background: #FFE2E2 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    font: normal normal bold 12px/18px Heebo;
    letter-spacing: 0px;
    color: hsla(0, 100%, 50%, 0.7);
}

.ClerkTable .text-medium {
    font: normal normal 500 18px/27px Poppins;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
}

.ClerkTable .hoverBlue:hover {
    color: #1E85F1;
}

.ClerkTable .orderActive {
    color: #1E85F1;
}

.ClerkTable .arrowImage {
    margin-left: 4px;
}

.ClerkTable .Loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
