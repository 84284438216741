.card-delete{
    width: 150px;
    height: 150px;
    background: #1E85F1 0% 0% no-repeat padding-box;
    border-radius: 100px;
    opacity: 1;
    margin-bottom: 20px;
}

.icon-delete{
    color: #FFF;
    font-size: 80px;
    margin-right: 10px;
}

.buttonCancelModal{
    top: 905px;
    left: 1334px;
    width: 194px;
    height: 52px;
    box-shadow: 0px 3px 6px #0000003D;
    border-radius: 12px;
    opacity: 1;
    background-color: #7A86FA;
    border-color: #7A86FA;

    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.buttonExcluirModal{
    background-color: #F80808;
    border-color: #F80808;
    top: 905px;
    left: 1548px;
    width: 194px;
    height: 52px;
    box-shadow: 0px 3px 6px #0000003D;
    border-radius: 12px;
    opacity: 1; 
    margin-left: 20px;

    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
}

.div-button-modal{
    display: flex;
    width: 100%;
    justify-content: center;
}

.row-button-modal{
    margin-top: 70px;
    margin-bottom: 50px;
}

.col-modal{
    width: 100%;
    justify-content: center;
}

.textAtencao{
    margin-bottom: -2px;
    text-align: center;
    font: normal normal bold 27px Poppins;
    letter-spacing: 0px;
    color: #F80808;
    opacity: 1;
}

.text1{
    margin-top: 7px;
    margin-left: 10px;
    text-align: center;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #4B5155;
}

.text2{
    text-align: center;
    font: normal normal normal 16px Poppins;
    letter-spacing: 0px;
    color: #4B5155;
    opacity: 1;
}