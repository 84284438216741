.card-delete{
    width: 150px;
    height: 150px;
    background: #1E85F1 0% 0% no-repeat padding-box;
    border-radius: 100px;
    opacity: 1;
    margin-bottom: 20px;
}

.icon-delete{
    color: #FFF;
    font-size: 80px;
}

.col-modal{
    width: 100%;
    justify-content: center;
}

.textSuccess{
    margin-bottom: -2px;
    text-align: center;
    font: normal normal bold 27px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    color: #00BF78;
}

.text{
    margin-top: 7px;
    margin-left: 10px;
    text-align: center;
    font: normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #4B5155;
}