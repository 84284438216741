.HistoryTable {
    display: flex;
    flex-direction: column;
    margin: 13px 25px;
    padding-bottom: 10px;
}

.HistoryTable .HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HistoryTable .HeaderContainer .Title {
    font: normal normal 500 19px/29px Poppins;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    margin-top: 8px;
}

.HistoryTable .HeaderContainer button {
    font: normal normal 500 19px/29px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;

    border: none;

    height: 40px;
    width: 128px;
    background: #7D7D7D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    opacity: 0.81;
}

.HistoryTable table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    vertical-align: middle;

    margin-top: 56px;
}

.HistoryTable table thead tr th {
    font: normal normal 500 19px/29px Poppins;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    padding: 6px 10px;
}

.HistoryTable table tbody tr td {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #7D7D7D;
    opacity: 1;
    padding: 6px 10px;
    /* max characters */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.HistoryTable .hoverBlue:hover {
    color: #1E85F1;
}

.HistoryTable .orderActive {
    color: #1E85F1;
}   

.HistoryTable .arrowImage {
    margin-left: 4px;
}

.HistoryTable .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}