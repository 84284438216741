.SearchBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 46px 0px 0px 31px;
}

.SearchBar .IdInput,
.SearchBar .TotemInput,
.SearchBar .UnitInput,
.SearchBar .Date,
.SearchBar .Button,
.SearchBar .TotemInput {
    text-align: left;
    font: normal normal 300 13px/20px Poppins;
    letter-spacing: 0px;
    color: hsla(213, 37%, 29%, 1);

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000003D;
    border-radius: 7px;
    opacity: 1;

    border: none;
    padding: 0px 20px;
    margin-bottom: 13px;
    margin-right: 24px;
}

.SearchBar .IdInput { 
    width: 138px;
    height: 38px;    
}

.SearchBar .TotemInput { 
    width: 166px;
    height: 38px;    
}

.SearchBar .UnitInput { 
    width: 179px;
    height: 38px;   
}

.SearchBar .Date {
    width: 176px;
    height: 38px;
}

.SearchBar .Date::-webkit-calendar-picker-indicator {
    background: url('../../assets/calendar-svgrepo-com.svg') no-repeat;
    background-size: 20px;
}

.SearchBar .Button {
    width: 66px;
    height: 38px;

    background: hsla(211, 88%, 53%, 0.3) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;

    border: none;
}

.SearchBar .Button > img {
    height: 23px;
    width: 23px;
}

.SearchBar .ReturnBtn {
    width: 38px;
    height: 38px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsla(211, 88%, 53%, 0.4) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    
    border: none;
}

.SearchBar .ReturnBtn img{
    width: 18px;
    height: 18px;
}
