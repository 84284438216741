.TopBar {
    background: hsla(211, 88%, 53%, 0.06) 0% 0% no-repeat padding-box;
    height: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TopBar img {
    opacity: 1;
    height: 150px;
}

.TopBar .UserInfo {
    display: flex;
    align-items: center;
}

.TopBar .UserInfo img {
    opacity: 1;
    height: 54px;
    width: 54px;
    border-radius: 50%;
}

.TopBar .UserInfo span {
    font: normal normal 500 19px/71px Poppins;
    letter-spacing: 0px;
    color: hsla(0, 0%, 44%, 1);
    opacity: 1;
    padding-left: 14px;
}