.EditUser .title{
    font: normal normal 500 19px/29px Poppins;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
}

.EditUser .btn-circle.btn-sm {
    width: 40px;
    height: 40px;
    padding-bottom: 7px;
    padding-left: 7px;
    border-radius: 25px;
    font-size: 15px;
    text-align: center;
    opacity: 0.4;
    background: #1E85F1 0% 0% no-repeat padding-box;
    outline-style: none;
}

.EditUser .btn-form-img {
    color: #1E85F1;
    width: 40px;
    height: 40px;
    padding-bottom: 7px;
    padding-left: 7px;
    font-size: 15px;
    text-align: center;
    background: #FFF;
}

.EditUser .row-title{
    margin: 40px 40px 20px 100px;
    margin-top: 100px;
}

.EditUser .row-form{
    margin: 81px 10px 20px 100px;
}

.EditUser .col-title{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 1000px;
    justify-content: flex-start;
    align-content: center;
}

.EditUser .col-button{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 1000px;
    justify-content: flex-end;
}

.EditUser .card{
    width: 197px;
    height: 195px; 
    background: #FFE2E2 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
}

.EditUser .cardText{
    text-align: center;
    font: normal normal bold 35px Poppins;
    letter-spacing: 0px;
    color: #FF0000;
    opacity: 0.7;
}

.div-delete-button{
    display: flex;
    width: 10%;
    justify-content: flex-start;
    margin-left: 90px;
}

.div-card{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.EditUser .row-button{
    margin-top: 200px;
}

.EditUser .buttonCancel{
    top: 905px;
    left: 1334px;
    width: 194px;
    height: 52px;
    box-shadow: 0px 3px 6px #0000003D;
    border-radius: 12px;
    opacity: 1;
    background-color: #7A86FA;
    border-color: #7A86FA;
}

.EditUser .buttonSave{
    background-color: #00BF78;
    border-color: #00BF78;
    top: 905px;
    left: 1548px;
    width: 194px;
    height: 52px;
    box-shadow: 0px 3px 6px #0000003D;
    border-radius: 12px;
    opacity: 1; 
    margin-left: 20px;
}

.EditUser .div-button{
    display: flex;
    width: 90%;
    justify-content: flex-end;
}

.EditUser .icon {
    margin-top: 10px;
    color: #1E85F1;
}