.bottom-button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  /* Botões de paginação */
  .pagination-button-wrapper {
    display: flex;
    align-content: space-between;
  }
  
  .pagination-button-navigation {
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF 0% 0% no-repeat padding-box;
    margin: auto;
    width: 10px;
    margin-bottom: 4.5px;
  }
  
  /* Retirar margin do primeiro botão */
  .pagination-button-navigation:first-child {
    margin-left: 0px;
  }

  .pagination-text1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 22px;
    width: 25px;
    border:solid 1px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #7D7D7D;
  }

  .pagination-text2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 45px;
    border:none;
    opacity: 1;
    margin-top: 0.5px;
    text-align: left;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #7D7D7D;
  }
  