.card-one{
    -webkit-box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.77);
      -moz-box-shadow:    1px 1px 5px rgba(50, 50, 50, 0.77);
      box-shadow:         1px 1px 5px rgba(50, 50, 50, 0.77);
    width: 248px;
    height: 336px;  
    opacity: 1;
    margin-bottom: 50px;
    margin-top: 50px;
}
  
.cardTitle{
    text-align: center;
    font: normal normal bold 16px Poppins;
    letter-spacing: 0.32px;
    opacity: 1;
}
  
.cardSubTitle{
    text-align: center;
    font: normal normal bold 32px Poppins;
    letter-spacing: 0.32px;
    opacity: 1;
}