.AccessHistory {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px - 51px); /* 100viewheight - 100px of top bar - 51px of search bar */
    margin: 28px 60px 10px 0px;
}

.AccessHistory .TableContainer {
    margin: 14px 0px 0px 0px;
    padding-bottom: 20px;
    overflow-y: hidden;
}

.AccessHistory .TableContainer .TableCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 2px 20px #00000029;
    border-radius: 8px;
    margin: 10px 28px 20px 31px;
    padding: 0px;
    max-height: 100%;
    overflow-y: auto;
}

.AccessHistory .ReturnBtn {
    width: 38px;
    height: 38px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsla(211, 88%, 53%, 0.4) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    
    border: none;
}

.AccessHistory .ReturnBtn img{
    width: 18px;
    height: 18px;
}

.AccessHistory .ReturnButton{
    display: flex;
    justify-content: flex-end;
}