.Container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 100px); /* 100% of viewport height - 100px (height of topbar) */
    overflow: hidden;
}

.Container .Menu {
    background: hsla(211, 88%, 53%, 0.06) 0% 0% no-repeat padding-box;
    width: 275px;
    min-width: 275px;
    max-width: 275px;
}

.Container .Menu > nav {
    display: flex;
    flex-direction: column;
    padding: 9rem 20px 0 20px;
}

.Container .Menu .LinkContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.Container .Menu .LinkContainer:not(:first-child) {
    margin-top: 30px;
}

.Container .Menu .LinkContainer img {
    width: 25px;
    margin: 0 20px;
}

.Container .Menu .LinkContainer .Menu-Link {
    text-decoration: none;
    font: normal normal normal 24px/35px Poppins;
    letter-spacing: 0px;
    color: hsla(0, 0%, 26%, 1);
    opacity: 1;
}

.Container .Menu .LinkContainer .Menu-Link:hover {
    cursor: pointer;
}

.Container .Menu .LinkContainer .Menu-LinkSelected {
    font-weight: bold;
}

.Container .Content {
    width: 100%;
    background-color: white;
    overflow-y: auto;
}
