.Home {
    margin: 28px 60px 10px 60px;
}

.Home .TableContainer {
    margin: 0px 0px 10px 0px;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}

.Home .TableContainer .TableCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 10px 28px 35px 31px;
    padding: 0px;
    overflow: auto;
    overflow-y: hidden;
}

.HomePageLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
}