.Dash .title-und{
  font: normal normal 500 19px/29px Poppins;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
  margin: 20px 10px 30px 70px;
}

.Dash .row-dash {
  margin: 20px 10px 30px 100px;
  max-height: 70vh;
}

.card-date{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 8px #0000003D;
  border-radius: 10px;
  opacity: 1;
  margin-right: 20px;
}

.DateRange{
  margin: 30px 20px 0px 70px;
  top: 100px;
  display: flex;
  direction: row;
}

.text-date{
  text-align: left;
  font: normal normal medium 20px/40px SF Pro Text;
  letter-spacing: 0px;
  color: #464D5C;
  opacity: 0.6;
  margin-left: 10px;
  margin-top: 1px;
}

.div-card{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.text-confirmar{
  text-align: left;
  font: normal normal medium 20px/40px SF Pro Text;
  letter-spacing: 0px;
  color: #464D5C;
  opacity: 0.6;
  margin-left: 15px;
  margin-top: 1px;
}

.text-limpar{
  text-align: left;
  font: normal normal medium 20px/40px SF Pro Text;
  letter-spacing: 0px;
  color: #464D5C;
  opacity: 0.6;
  margin-top: 1px;
}

.div-button-calendar{
  display: flex;
  margin-left: 226px;
  justify-content: flex-start;
}

.button-confirmar-date{
  border-radius: 7px;
  border: none;
  text-align: left;
  font: normal normal medium 8px/27px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.button-limpar-date{
  border-radius: 7px;
  border: none;
  margin-right: 10px;
  text-align: left;
  font: normal normal medium 8px/27px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.button-xls{
  border: none;
  width: 127px;
  height: 46px;
  background: #7D7D7D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  opacity: 0.81;
  
  font: normal normal medium 19px/29px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.HomeDashLoad {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
}

.Dash .Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
}

