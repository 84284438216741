.card-unidade{
    margin: 20px 10px 30px 110px;
    width: 85%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 8px #0000003D;
    border-radius: 10px;
    opacity: 1;
}
  
.title1{
    text-align: left;
    font: normal normal 500 16px/25px Poppins;
    letter-spacing: 0px;
    color: #45484B;
    opacity: 1;
}
  
.title2{
    text-align: right;
    font: normal normal 500 16px/25px Poppins;
    letter-spacing: 0px;
    color: #45484B;
    opacity: 1;
}